import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const SContactDetailTitle = styled.p`
    font-size: ${(props) => props.theme.fontSize.large};
    color: ${(props) => props.theme.color.grey_900};
    line-height: ${(props) => props.theme.lineHeight.small};
`;
export const SContactDetailDescription = styled.p`
    font-size: ${(props) => props.theme.fontSize.large};
    color: ${(props) => props.theme.color.grey_400};
    margin-bottom: 40px;

    a {
        font-size: ${(props) => props.theme.fontSize.large};
        color: ${(props) => props.theme.color.grey_400};
    }
`;
export const SCol = styled(Col)``;
