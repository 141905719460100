import React from 'react';
import { Row } from 'react-bootstrap';
import ConsultationForm from '../../components/ConsultationForm';
import { SPageH1Title, StyledContainer } from '../Layout/styles';
import { SContactDetailTitle, SContactDetailDescription, SCol } from './styles';
import Breadcrumbs from '../../components/Breadcrumbs';

const contacts = [
    {
        title: 'Administracijos adresas',
        description: 'Baltų pr. 193A-35, LT-47120, Kaunas',
    },
    {
        title: 'Įmonės pavadinimas',
        description: 'Finansinių sprendimų agentūra, UAB',
    },
    {
        title: 'Įmonės kodas',
        description: '304225150',
    },
    {
        title: 'Įmonės sąskaita',
        description: 'LT197044060008088392',
    },
    {
        title: 'Telefono numeris',
        description: <a href="tel:+370 636 40444">+370 636 40444</a>,
    },
    {
        title: 'Elektroninis paštas',
        description: <a href="mailto:info@fsa.lt">info@fsa.lt</a>,
    },
];

const ContactDetail = ({ title, description }) => (
    <>
        <SContactDetailTitle>{title}</SContactDetailTitle>
        <SContactDetailDescription>{description}</SContactDetailDescription>
    </>
);

const ContactsContainer = () => {
    return (
        <StyledContainer fluid>
            <Breadcrumbs title="Kontaktai" />
            <Row>
                <SCol xs={12} lg={6} className="pe-lg-5">
                    <SPageH1Title>Kontaktai</SPageH1Title>
                    {contacts.map((contact, index) => (
                        <ContactDetail key={index} {...contact} />
                    ))}
                </SCol>
                <SCol xs={12} lg={6}>
                    <ConsultationForm />
                </SCol>
            </Row>
        </StyledContainer>
    );
};

export default ContactsContainer;
