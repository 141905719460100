import React from 'react';
import ContactsContainer from '../containers/ContactsContainer';
import MapContainer from '../containers/MapContainer';


const Kontaktai = () => {
    return (
        <>
            <ContactsContainer />
            <MapContainer />
        </>
    );
};

export default Kontaktai;
